@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
// app global css in SCSS form

@import "common";
@import "application";

@font-face {
  font-family: 'Roboto Condensed';
  src: url('../assets/fonts/RobotoCondensed-Light.woff2') format('woff2'),
      url('../assets/fonts/RobotoCondensed-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: url('../assets/fonts/RobotoCondensed-LightItalic.woff2') format('woff2'),
      url('../assets/fonts/RobotoCondensed-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: url('../assets/fonts/RobotoCondensed-Italic.woff2') format('woff2'),
      url('../assets/fonts/RobotoCondensed-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: url('../assets/fonts/RobotoCondensed-Regular.woff2') format('woff2'),
      url('../assets/fonts/RobotoCondensed-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: url('../assets/fonts/RobotoCondensed-BoldItalic.woff2') format('woff2'),
      url('../assets/fonts/RobotoCondensed-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: url('../assets/fonts/RobotoCondensed-Bold.woff2') format('woff2'),
      url('../assets/fonts/RobotoCondensed-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body{
  font-family: "Roboto Condensed", "Roboto", "-apple-system", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0px;
}

.body--dark .bg-white{
  background-color: var(--q-color-dark)!important;
}

.bg-red {
  background-color: $red !important;
}

body.body--dark, body.body--dark .q-color-primary {
  color: $darken-4!important;
}

.q-dialog__inner > div{
  box-shadow: none!important;
}

body.body--dark{

  .deals-charts, .deals-dashboard{
    .q-table__control{
      background-color: #1d1e24;
    }
    .q-table__middle{
      background: #2d2d2d;
    }
    --q-color-secondary: #565966!important;
    .bg-grey-2, .sticky thead tr:first-child th, .sticky .q-table__top, .sticky .q-table__bottom, .sticky thead tr:first-child th{
      background-color: #2d2d2d !important;
    }
    .apexcharts, .apexcharts-legend-text{
      color: #fff!important;
      fill: #fff!important;
    }
    .apexcharts-menu-item{
      color: black;
      fill: black;
    }
    .apexcharts-text tspan{
      fill: #fff!important;
    }
  }

  .q-tab {
    color: white!important;
  }
  .fc-event-main :not(.q-avatar__content) i.q-icon{
    color: #1d1e24;
  }
  .fc-event-main .mdi-checkbox-multiple-blank-outline, .fc-event-main .mdi-calendar-lock{
    color: #fff!important;
  }

  --q-color-secondary: #f8d480!important;

  .bg-amber-1{
    background-color:  #f8d480!important;

  }
  .bg-yellow{
    background-color: #0014c4!important;
  }
  .bg-lime-1{
    background-color: #060418!important;
    // background-color: $lime-10!important; // 060418
  }
  .bg-yellow-3{
    background-color: #000a62!important;
  }

  $primary: #F05252;
  .text-primary{
    color: $primary!important;
  }

  .datatable-header{
    background-color: $darken-3!important;
  }
  .q-toolbar{
    background-color: $darken-4;
  }
  .q-field--highlighted .q-field__label, .q-field--dark .q-field__marginal{
    color: white!important;
  }
  .q-field--filled .q-field__control:after, .q-field--dark .q-field__control:before{
    content: none;
  }
  .q-field__control.text-negative{
    border: 1px solid var(--material-vuetify-red-lighten-1-ef-5350, #EF5350)!important;

  }
  .q-field--error .q-field__bottom{
    color: #EF5350;
  }

  .bg-grey-4{
    background-color: #131418!important;
  }

  .q-field__native, .q-field__prefix, .q-field__suffix, .q-field__input{
    background-color: $darken-2!important;
  }

  .q-hoverable:hover > .q-focus-helper {
    background-color: $darken-3!important;
    opacity: 1;
  }

  .q-field--filled.q-field--dark .q-field__control, .q-field--filled.q-field--dark .q-field__control:before{
    background-color: $darken-2!important;
  }

  .q-field--filled.q-field--readonly .q-field__control:before{
    border: none;
  }
  .q-item.q-router-link--active, .q-item--active, .q-icon{
    color: white;
  }
}

.q-layout__section--marginal{
  background-color: #fff;
  color: black!important;
}

.text-subtitle1, .q-table__title{
  letter-spacing: 0px !important;
  font-weight: 700;
}

.q-table--dark tr{
  background-color: $darken-4;
}
.q-table--dark tr:hover{
  background-color: $darken-3;
}

.q-table--dark .q-table__bottom, .q-table--dark thead, .q-table--dark tr, .q-table--dark th, .q-table--dark td{
  border: 1px solid $darken-3;
}

.q-list--dark, .q-item--dark, body.body--dark .q-header, body.body--dark .q-footer, body.body--dark .q-drawer,
.q-table--vertical-separator .q-table__top, .q-table--cell-separator .q-table__top,
.q-table--dark table{
  border-color: $darken-3 !important;
}

.q-btn__wrapper:before{
  box-shadow: none !important;
}

table th{
  font-weight: 700!important;
}
::-webkit-scrollbar {
  height: 12px;
  width: 14px;
  background: transparent;
  z-index: 12;
  overflow: visible;
}

::-webkit-scrollbar-thumb {
  width: 10px;
  background-color: $teal-5;
  border-radius: 10px;
  z-index: 12;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  transition: background-color .32s ease-in-out;
  margin: 4px;
  min-height: 32px;
  min-width: 32px;
}

::-webkit-scrollbar-thumb:hover {
  background: $teal-5;
}

.bg-primary{
  background-color: $brand!important;

}

.body--dark{

  ::-webkit-scrollbar-track {
    scrollbar-color: $darken-3 $darken-4!important;
  }

  .q-tab__content--inline .q-tab__icon + .q-tab__label{
    color: $grey-5;
  }

  .bg-blue-grey-2{
    background-color: $grey-5;
  }
  .q-tab__indicator.text-primary{
    color: #fff!important;
  }
  .q-tab--active .q-tab__label{
    color: #fff!important;
  }

  .bg-blue-grey-2, .bg-green-1{
    background-color: $blue-grey-10!important;
  }

  .q-field--dark .q-field__control, .q-field--dark .q-field__control:before{
    background-color: $darken-2 !important;
    border-radius: 4px;
    border: 1.5px solid $darken-1;
  }

  .q-checkbox__inner.text-primary{
    color: $teal-5!important;
    .q-checkbox__svg{
      color: $darken-4!important;
    }
  }
  .q-checkbox--dark .q-checkbox__inner--truthy, .q-checkbox--dark .q-checkbox__inner--indet{
    color: $teal-5!important;
    .q-checkbox__svg{
      color: $darken-4!important;
    }
  }
  .q-table__control{
    background-color: $darken-5!important;
    border-color: $darken-3;
    .q-field__native{
      background-color: transparent!important;
    }
  }

  .q-table__container .q-field__control.text-grey-8{
    border-color: currentColor!important;
  }
  .q-table--vertical-separator .q-table__top, .q-table--cell-separator .q-table__top{
    border-color: $darken-1!important;
  }
  .fc-theme-standard td, .fc-theme-standard th, .fc-theme-standard .fc-scrollgrid{
    border: 1px solid $darken-1!important;
  }
  .q-separator--dark{
    background-color: $darken-2;
  }
  .text-black{
    color: #fff!important;
  }

  .q-avatar{
    background-color: $darken-2!important;
  }
  .q-tree{
    > .q-tree__node{
      margin-top: 10px;
    }
  }
  .q-tree__node{
    background-color: $darken-3;
    border-radius: 10px;
    padding-right: 10px;
  }
} // dark
.q-field__control{
  padding: 0 12px;
}

.q-tab__label{
  font-weight: 700;
}

.q-layout__shadow:after{
  box-shadow: none!important;
}

.q-field__control{
  background-color: $grey-1 !important;
  border-radius: 5px;
  border: 1.5px solid $grey-3;
  padding: 0 10px!important;
}
.q-table__control{
  background-color: $grey-2 !important;
  border-radius: 5px;
  border: 2px solid $grey-3;
  padding: 5px 10px;
  margin-left: 10px;

}

.q-table__bottom{
  font-size: 14px;
  letter-spacing: -0.5px;
  .q-table__control{
    padding: 8px 12px 8px 12px;
    height: 44px;
    .q-field__input{
      background-color: transparent!important;
    }
  }
  .q-select--single{
    margin-right: -15px;
  }

  .q-table__control:last-child .q-table__bottom-item{
    margin-right: 0;
  }
}

.q-table__container .q-field__control.text-grey-8{
  background: none!important;
  border: none!important;
  border-left: 1px solid $grey-3!important;
  border-radius: 0px!important;
  padding-right: 0;
  padding-left: 15px!important;
  padding-top: -10px!important;
  height: 30px!important;
  min-height: 30px!important;
}
.q-table__container{
  .q-field--dense .q-field__control, .q-field--dense .q-field__marginal{
    height: inherit!important;
  }
}

.datatable-header{
  background-color: #fff;
}

.q-table--vertical-separator .q-table__top, .q-table--cell-separator .q-table__top {
  border-bottom: 1px solid $grey-2!important;
}

.q-checkbox__inner.text-primary{
  color: $teal-5!important;
  .q-checkbox__svg{
    color: #fff!important;
  }
}
.q-checkbox--dark .q-checkbox__inner--truthy, .q-checkbox--dark .q-checkbox__inner--indet{
  color: $teal-5!important;
  .q-checkbox__svg{
    color: #fff!important;
  }
}

.q-table__container{
  border-radius: 5px;
}

.q-tab-panel{
  padding: 16px 0px;
}
.q-table__card, .q-card{
  box-shadow: none!important;
}

.q-card{
  border-radius: 10px;
}

.text-h6{
  letter-spacing: -1px;
}
.q-separator--dark{
  box-shadow: none!important;
}
.YL__toolbar-input-container input {
  height: 38px;
}

.q-timeline--dense--right .q-timeline__entry{
  margin-left: 11px;
}

.q-field__control{
  overflow: hidden;
}

.required .q-field__label:after{
  color: red;
  content: " *"!important;
}

